import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
              White Label Enjin Clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph">The Enjin clone script is the ready-to-deploy NFT Marketplace software that comprises all the high-end technical features to launch a superfine NFT Marketplace similar to Enjin. The Enjin clone script is a complete white-label script that allows you to build your dream NFT Marketplace within a week at an affordable cost. This script holds all the primary features that an NFT Marketplace should have. such as NFT minting of digital collectibles, multiple wallet connection support, prompt search results, QR scan, etc.
            </p>
            <p className="pharagraph mb-0">Our premium Enjin clone script is forged on the most decentralized, scalable, and robust blockchain network - Ethereum. Our clone script is a cutting-edge admin portal which is a one-of-a-kind framework that allows you to manage the functionalities of the platform and NFTs on the Ethereum blockchain in a seamless way. This script is multi-tested and designed by our well-experienced team of blockchain developers. Therefore, you can launch a function-rich NFT Marketplace like Enjin without any technical hindrances.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis