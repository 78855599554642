import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  render() {

    return (
      <section className="whyshould pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h2 className="heading-h2"><span className="heading-h3">Why should you Build an</span> NFT Marketplace using Enjin Clone Script?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="443px" height="584px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/why-should-you-build-enjin.webp" alt="enjin clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Comes with highly advanced and thriving NFT Marketplace features.
                </li>
                <li className="pharagraph" >The premium Enjin clone script is highly responsive and comes with several customizable options.
                </li>
                <li className="pharagraph" >Faster deployment.
                </li>
                <li className="pharagraph" >A cost-effective way to create a fully functional NFT Marketplace like Enjin.
                </li>
                <li className="pharagraph" >The architecture of the script is designed on the Ethereum blockchain network.
                </li>
                <li className="pharagraph" >Your users can turn their desired digital collectibles into tradeable NFTs. </li>
                <li className="pharagraph" >Comes with the latest QR mode of scanning and processing transactions.
                </li>
                <li className="pharagraph" >Users can purchase trending NFTs and transfer them into crypto wallets instantly and securely.
                </li>
                <li className="pharagraph" >Generate massive ROI with your out-of-the-box strategies.
                </li>
                <li className="pharagraph" >The script is 100% multi-tested and bug-free. So, this clone script is free from all technical hurdles & glitches.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould