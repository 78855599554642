import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              Enjin Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Day/Night Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Affiliate Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                NFT Gifting
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                Multiple Payment Options
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation
                      </h4>
                      <p className="pharagraph">Enjin clone script is for everyone! It can be built in a way where all the sectors of users can utilize it for their preferred purposes like individuals, businesses and developers. Every sector will get its unique rest API doc using which they can pull a set of data through parameters that include sales, bids, transfers and more.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="374px" height="302px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/api-documentation.webp" alt="API Documentation" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Day/Night Mode
                      </h4>
                      <p className="pharagraph">Our Enjin clone script is scalable and expandable to offer a dual mode of experience in operating the system. Users can switch to dark or light mode and enjoy the platform at their convenience.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="579px" height="323px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/night-modeb.webp" alt="Day/Night Mode" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Affiliate Program
                      </h4>
                      <p className="pharagraph">Existing users can refer their friends and surroundings using the referral link or referral code we provide for each user. When a new user joins the platform using that code, the referred person will get some rewards from the admin. This also helps in expanding the customer base and trade volume of the system.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="276px" height="280px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/affiliate-program.webp" alt="Affiliate Program" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">NFT Gifting</h4>
                      <p className="pharagraph">The Enjin clone script we provide allows its users to send NFTs as a gift to other  users. They can also receive NFT gifts from friends on any platform. Users can send an NFT gift to their ETH address so they receive it directly in their wallet.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="327px" height="327px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/nft-gifting.webp" alt="NFT Gifting" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Multiple Payment Options
                      </h4>
                      <p className="pharagraph">Users can not only bid or buy NFTs using crypto payments, but also they can do that using fiat payments that can be done through credit/debit cards, payment gateways, etc..
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="420px" height="329px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/multiple-payment-options.webp" alt="Multiple Payment Options" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon