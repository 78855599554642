import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Enjin</span> Clone Script</h1>
                <p className="sub-heading">Get our feature-packed Enjin clone script to build the strongest and most reliable NFT Marketplace platform with breathtaking features. Commence your dream NFT Marketplace business in the minimum possible time by using our white-label Enjin clone script.</p>
                <ul>
                  <li className="pharagraph">An astute solution to begin an NFT Marketplace business
                  </li>
                  <li className="pharagraph">Built over the Ethereum blockchain network.
                  </li>
                  <li className="pharagraph">100% tested and optimizable NFT Marketplace clone script.
                  </li>
                </ul>
                <div className="banner-btn">
                  <ButtonStartfree />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png" alt="enjin clone laptop front view" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/enjin-clone-script.webp" alt="white label enjin clone script software" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/enjin-clone.webp" alt="enjin clone script" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/enjin.webp" alt="enjin-clone" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner