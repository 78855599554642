import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of</span>
            Our Enjin Clone Script </h3>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for Enjin clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="371px" height="349px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/planning.webp" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >Gather as much information as possible about what you want to achieve with the Enjin clone script vis-à-vis the NFT Marketplace. Accordingly, we will make recommendations, draw a plan, and present you with an estimate.         
            </p>
            <br />
            <h5 >Planning </h5>
            <p className="pharagraph" > This is to give you a visual sense of how your marketplace is going to shape up around the Enjin clone script capabilities. We may use hand sketches or slide decks to present our ideas to you.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >Based on the analysis and the approval of the prototype, we will design and develop your NFT platform using our Enjin clone script. This includes coding and UI/UX design as per your preferences and branding needs.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">After developing, designing and testing, we will deploy the platform on the web. In case you have an app, we will also deploy the Enjin clone script powered mobile app on Apple and Google Play Stores.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="425px" height="373px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/designing.webp" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="373px" height="285px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/testing.webp" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">This is just to make sure that everything works the way it should and there are no kinks.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">The application will be deployed on its appropriate platform along with the Enjin clone script.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach