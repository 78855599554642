import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid =()=> {


    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            
            <h3 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App</span></span> For Enjin Clone App Development</h3>
            <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency trading mobile app like Enjin
            </p>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2">
              <p className="pharagraph" >Launch a user-friendly NFT trading mobile app like Enjin in a most compatible way by using our first-class Enjin clone app development services. Our Enjin clone script enables you to build an exclusive NFT Marketplace mobile application like Enjin in the shortest possible time. It is a multi-tested and customizable NFT Marketplace script that can be used to build web-based interfaces as well as mobile apps.
              </p>
              <p className="pharagraph" >In the Enjin clone app, your users can buy and sell all sorts of NFTs securely in a stress-free way. Our clone app is built with robust security features and an attractive user-friendly interface. Additionally, the clone app we deliver is capable of minting tokens and the management of digital assets.
              </p>
              <p className="pharagraph" >The Enjin clone script app development also includes optimization. So, you can alter the visuals, features, and other plug-ins according to your business concepts with ease. Once the app is deployed and begins to perform, your users can use it to buy, sell, bid, create, and store non-fungible tokens. So, your users can trade NFTs at any time from anywhere.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/enjin/enjin-clone-app-development.png" alt="enjin clone app development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
          <div className='mobile-on'>
          <ButtonComponent />
          </div>
        </div>
      </section>
    )
}

export default OurAndroid