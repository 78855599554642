import React from 'react'




class QuickviewSection extends React.Component {


  render() {
    return (
      <section className="quick pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Quick View</span> of </span>
              Our Enjin Clone Script</h2>
          </div>
          <div className="text-center">
            <video className="lazyload" width="1137" height="622" controls preload={true}  muted={true} playsinline>
              <source src="https://coinsclone.mo.cloudinary.net/images/enjin/enjinvideo.mp4?cld-content-marker=jit" type="video/mp4"></source>
            </video>
          </div>
        </div>
      </section>
    )
  }
}

export default QuickviewSection